import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PromocodesModal, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getCategories } from '../../store/actions';
import getPromocodes from '../../store/actions/getPromocodes';
import { deletePromocode } from '../../api/restaurantApi.service';
import './style.css';

let ps: any;
const Promocodes: FC = (props) => {
  const [modal, setModal] = useState(false);
  const [promoId, setPromoId] = useState(0);
  const [PromoObj, setPromoObj] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const categories_list = useSelector(({ categories }: StoreRoot) => categories);
  const promocodes_list = useSelector(({ promocodes }: StoreRoot) => promocodes);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Promocodes';
    // @ts-ignore
    document.getElementById('promocodes_colorchange').style.color = 'darkred';
    dispatch(getCategories(id));
    dispatch(getPromocodes(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, promo: any) => {
    try {
      // toggle used to close modal with id === event
      id?.persist();
      id?.preventDefault();
    } catch (err) {
      // id is not event object
    }

    setModal(!modal);
    setPromoId(id);
    setPromoObj(promo);
  };

  const deletePromo = async (promo_id: any) => {
    const restid = localStorage.getItem('rest_id') as string;

    deletePromocode(id, promo_id)
      .then((res) => {
        toast.success('Promo removed', { containerId: 'B' });
        dispatch(getPromocodes(id));
      })
      .catch((err) => {
        toast.error('Promo not removed', { containerId: 'B' });
        dispatch(getPromocodes(id));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {promocodes_list && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Promocodes</span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-danger rounded-0 shadow-none"
              >
                + Voeg promocode toe
              </button>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="small">Naam</th>
                      <th className="small">Code</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promocodes_list.map((promo: any) => {
                      return (
                        <tr key={promo.id}>
                          <td>{promo.name}</td>
                          <td>{promo.code}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={promo.id}
                              onClick={() => toggle(promo.id, promo)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              onClick={() => deletePromo(promo.id)}
                              id={promo.id}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <PromocodesModal promo={PromoObj} modal={modal} toggle={toggle} promoId={promoId} />
    </>
  );
};
export default Promocodes;
