import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import Select from 'react-select';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API } from '../api';
import { getCategories } from '../store/actions';
import moment from 'moment';

const promoTypesOptions = [
  {
    value: 'FIXED_AMOUNT',
    label: 'Fixed amount',
  },
  {
    value: 'PERCENTAGE',
    label: 'Percentage',
  },
];

const PromocodesModal: FC<any> = (props) => {
  const [promo, setPromo] = useState<any>(null);
  const [promoId, setPromoId] = useState<any>('');
  const [promoCtgs, setPromoCtgs] = useState<any[] | null>(null);
  const [promoType, setPromoType] = useState<any>(null);
  const [promoStartDate, setPromoStartDate] = useState<any>('');
  const [promoEndDate, setPromoEndDate] = useState<any>('');
  const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);

  const restId = localStorage.getItem('rest_id') as string;

  const categories_list = useSelector(({ categories }: StoreRoot) => categories);

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setPromoId(props.promoId);
    if (typeof props.promo === 'undefined') {
      setPromo(null);
      setPromoCtgs(null);
      setPromoType(null);
      setPromoStartDate('');
      setPromoEndDate('');
    } else {
      if (props.promo === 'new') {
        setPromo({
          name: '',
          code: '',
          categories: null,
          promocode_type: null,
          discount: '',
          start_date: null,
          end_date: null,
          min_cost: '',
        });
        return;
      }
      setPromo(props.promo);
      if (promo?.categories && promo?.categories?.length > 0) {
        const existingCtgs = promo.categories.map((ctg: any) => ({
          value: ctg.id,
          label: ctg.name,
          id: ctg.id,
        }));

        setPromoCtgs(existingCtgs);
      }
      const currentType = promoTypesOptions.filter(
        (t: any) => t.value === props.promo.promocode_type
      )[0];
      setPromoType(currentType);
      if (promo?.start_date) {
        const currentStartDate = moment(promo?.start_date);
        if (currentStartDate.isValid()) setPromoStartDate(currentStartDate.toDate());
      }
      if (promo?.end_date) {
        const currentEndDate = moment(promo?.end_date);
        if (currentEndDate.isValid()) setPromoEndDate(currentEndDate.toDate());
      }
    }
  }, [props]);

  useEffect(() => {
    if (categories_list?.length > 0) {
      // Add "Alle" option to selecte all the categories
      let newCtgsOptions = [...categories_list];
      newCtgsOptions.unshift({
        value: 'ALL',
        label: 'Alle',
        id: -1000,
        restaurant: parseInt(restId),
      });

      setCategoriesOptions(newCtgsOptions);
    }
  }, [categories_list]);

  const handleOption = (e: any) => {
    let new_value = { ...promo };
    new_value[e.target.name] = e.target.value;
    setPromo(new_value);
  };

  const handlePromoType = (val: any) => {
    let new_value = { ...promo };
    new_value.promocode_type = val.value;
    setPromo(new_value);
    setPromoType(val);
  };

  const handlePromoCtgs = (val: any) => {
    if (!val || val?.length === 0) {
      // Clear request
      setPromoCtgs(null);
      return;
    }
    // Check if "Alle" option already selected
    const allIndex = promoCtgs?.findIndex((promoCtg: any) => promoCtg?.value === 'ALL');
    // Check 'undefined' in case allIndex is 0
    if (allIndex === -1 || allIndex === undefined) {
      // Check if "All" is new selected option
      const allNewIndex = val?.findIndex((promoCtg: any) => promoCtg?.value === 'ALL');
      if (allNewIndex === -1) {
        // If category "All" not among selected
        setPromoCtgs(val);
      } else {
        // Replace all current promoCtgs with "All"
        setPromoCtgs([val[allNewIndex]]);
      }
    } else {
      toast.error("You've already selected all the categories", { containerId: 'B' });
    }
  };

  const handleStartDate = (val: any) => {
    setPromoStartDate(val);
  };

  const handleEndDate = (val: any) => {
    setPromoEndDate(val);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const id = localStorage.getItem('rest_id');

    const promo_obj = { ...promo, start_date: true, end_date: true };
    delete promo_obj.categories;

    const isPromoFieldsEmpty = Object.values(promo_obj).some((x) => !x);
    console.log({ ...promo, start_date: true, end_date: true });

    if (isPromoFieldsEmpty) {
      toast.error('Please, fill in all required fields', { containerId: 'B' });
      return;
    }

    let submit_ctgs: any[] = [];
    // Check if 'All' category option selected
    const allIndex = promoCtgs?.findIndex((promoCtg: any) => promoCtg?.value === 'ALL');
    if (allIndex === -1 || allIndex === undefined) {
      // 'All' category options is not selected
      submit_ctgs = promoCtgs?.map((ctg: any) => ctg.id) || [];
    } else {
      submit_ctgs = categories_list?.map((ctg: any) => ctg.id) || [];
    }

    if (!submit_ctgs.length) {
      toast.error('Please, select categories', { containerId: 'B' });
      return;
    }

    const formData = new FormData();

    formData.append('name', promo.name);
    formData.append('code', promo.code);
    if (submit_ctgs?.length > 0) {
      submit_ctgs.forEach((ctg: any) => {
        formData.append('categories', ctg);
      });
    }
    formData.append('promocode_type', promo.promocode_type);
    formData.append('discount', promo.discount);
    formData.append('min_cost', promo.min_cost);
    if (promoStartDate)
      formData.append('start_date', moment(promoStartDate).format('YYYY-MM-DD HH:mm:ss'));
    if (promoEndDate)
      formData.append('end_date', moment(promoEndDate).format('YYYY-MM-DD HH:mm:ss'));

    if (props.promoId === 'new') {
      // Create promo
      if (id) {
        API.restaurantApi
          .createPromocode(id, formData)
          .then((res) => {
            toast.success('Promocode created', { containerId: 'B' });
            props.toggle();
          })
          .catch((err) => {
            toast.error('Promocode not created', { containerId: 'B' });
          });
      }
    } else {
      // Update promo
      if (id) {
        API.restaurantApi
          .updatePromocode(id, promo.id, formData)
          .then((res) => {
            toast.success('Promocode updated', { containerId: 'B' });
            props.toggle();
          })
          .catch((err) => {
            toast.error('Promocode not updated', { containerId: 'B' });
          });
      }
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.promoId === 'new' ? 'Voeg promocode toe' : 'Bewerk promocode'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Naam
                </label>
                <InputField
                  required
                  name="name"
                  placeholder="naam"
                  value={promo?.name || ''}
                  onChange={(e: any) => handleOption(e)}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Code
                </label>
                <InputField
                  required
                  name="code"
                  placeholder="code"
                  value={promo?.code || ''}
                  onChange={(e: any) => handleOption(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Categorieën
                </label>

                <Select
                  name="categories"
                  required
                  isMulti
                  options={categoriesOptions}
                  value={promoCtgs || null}
                  onChange={(e) => handlePromoCtgs(e)}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Type
                </label>

                <Select
                  name="promocode_type"
                  options={promoTypesOptions}
                  value={promoType}
                  onChange={(e) => handlePromoType(e)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Minimale bestelwaarde
                </label>
                <InputField
                  required
                  name="min_cost"
                  placeholder="Minimale bestelwaarde"
                  value={promo?.min_cost || ''}
                  onChange={(e: any) => handleOption(e)}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Waarde korting
                </label>
                <InputField
                  required
                  name="discount"
                  placeholder="Waarde korting"
                  value={promo?.discount || ''}
                  onChange={(e: any) => handleOption(e)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Geldig vanaf
                </label>
                <DatePicker
                  className="form-control"
                  selected={promoStartDate}
                  onChange={(date) => handleStartDate(date)}
                  dateFormat="dd/MM/yyyy HH:mm"
                  required
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  isClearable={true}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Geldig tot
                </label>
                <DatePicker
                  className="form-control"
                  selected={promoEndDate}
                  onChange={(date) => handleEndDate(date)}
                  dateFormat="dd/MM/yyyy HH:mm"
                  required
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default PromocodesModal;
