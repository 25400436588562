import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API } from '../../api';
import { addLoading, removeError, removeLoading } from './index';
import types from '../types';

export default function getPromocodes(
  id: string
): ThunkAction<Promise<void>, StoreRoot, unknown, AnyAction> {
  return async (dispatch) => {
    const entry = 'getPromocodes';

    dispatch(removeError(entry));
    dispatch(addLoading(entry));

    return API.restaurantApi
      .getPromocodes(id)
      .then((res) => {
        dispatch({
          type: types.SET_PROMOCODES_LIST,
          payload: res,
        });
      })
      .catch((err) => console.log('Error is', err.message))
      .finally(() => dispatch(removeLoading(entry)));
  };
}
