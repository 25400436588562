import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import AdminDashboard from './views/AdminDashboard';
import CsvUpload from './views/UploadCSV';
import EditArticle from './views/EditArticle';
import FoodCategory from './views/FoodCategory';
import ForgotPasswordReset from './views/ForgotPasswordReset';
import TablesQRCodes from './views/TablesQRCodes';
import ArticleOptions from './views/ArticleOptions';
import ArticleOptionsFiltered from './views/ArticleOptionsFiltered';
import ArticleOptionsName from './views/ArticleOptionsName';
import CreateArticleOptions from './views/CreateArticleOptions';
import Orders from './views/Orders';
import Printers from './views/Printers';
import ChangePassword from './views/ChangePassword';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import PrivateRoute from './Components/PrivateRoute';
import Promocodes from './views/Promocodes';

const App: FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/login" />
        </Route>
        <Route path="/auth/login" component={Login} />
        <PrivateRoute path="/admin/dashboard" component={AdminDashboard} />
        <PrivateRoute path="/admin/csvUpload" component={CsvUpload} />
        <PrivateRoute path="/admin/editArticle" component={EditArticle} />
        <PrivateRoute path="/admin/foodCategory" component={FoodCategory} />
        <PrivateRoute path="/admin/articleoptions" component={ArticleOptions} />
        <PrivateRoute path="/admin/articleoption/:id" component={ArticleOptionsFiltered} />
        <PrivateRoute path="/admin/articleoptionsName" component={ArticleOptionsName} />
        <PrivateRoute path="/admin/qrdonwload/:id" component={TablesQRCodes} />
        <PrivateRoute path="/admin/optiongroups/:id" component={CreateArticleOptions} />
        <PrivateRoute path="/admin/orders" component={Orders} />
        <PrivateRoute path="/admin/changePassword" component={ChangePassword} />
        <Route path="/admin/printers" component={Printers} />
        <PrivateRoute path="/admin/promocodes" component={Promocodes} />
        <Route
          path="/auth/restaurant/:restid/forgotPasswordReset/:code"
          component={ForgotPasswordReset}
        />
      </Switch>
      <ToastContainer enableMultiContainer containerId="B" position={toast.POSITION.TOP_LEFT} />
    </>
  );
};
export default App;
