import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Header, OrderModel, Sidebar } from '../../Components';
import { API } from '../../api';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Components/Loader';
import Select, { StylesConfig } from 'react-select';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import getRestaurantData from '../../store/actions/getRestaurantData';

let ps: any;

const orderStatusSelectOpt = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Rejected', label: 'Rejected' },
];

function Orders() {
  const [modal, setModal] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);
  const [startDate, SetStartDate] = useState<any>(new Date());
  const [endDate, SetendDate] = useState<any>(new Date());
  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Statusbestelling', key: 'Statusbestelling' },
    { label: 'Betalingstatus', key: 'Betalingstatus' },
    { label: 'Tafelnummer', key: 'Tafelnummer' },
    { label: 'OpmerkingKlant', key: 'Opmerkingklant' },
    { label: 'NaamKlant', key: 'NaamKlant' },
    { label: 'E_mail', key: 'E_mail' },
    { label: 'GSM', key: 'GSM' },
    { label: 'Adres', key: 'Adres' },
    { label: 'Bedrag', key: 'Bedrag' },
    { label: 'Voorwanneer', key: 'Voorwanneer' },
  ];
  const [data, setCsvData] = useState([]);
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<{ value: string; label: string } | null>(
    orderStatusSelectOpt[0]
  );

  const dispatch = useDispatch();
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);
  const [isAutoAcceptOrders, setAutoAcceptOrders] = useState<boolean>(false);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    document.title = 'Orders';
    // @ts-ignore
    document.getElementById('orders_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  const getAllOrdersList = async (id: string) => {
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    setIsLoading(true);

    await API.restaurantApi
      .getAllOrdersList(id, sdate, edate)
      .then((res: any) => {
        setIsLoading(false);
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Voorwanneer:
            d.delivery_time && d.tableid === -1
              ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
              : '',
        }));

        setCsvData(temCSVData);
        setOrderData(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    const rest_id = localStorage.getItem('rest_id') as string;

    getAllOrdersList(rest_id);
    dispatch(getRestaurantData(rest_id));
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    setAutoAcceptOrders(restaurant.auto_accept_orders);
  }, [restaurant]);

  const getFilterdata = async () => {
    const rest_id = localStorage.getItem('rest_id') as string;
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    await API.restaurantApi
      .getAllOrdersList(rest_id, sdate, edate)
      .then((res: any) => {
        console.log('restaurant APP', res);
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Voorwanneer:
            d.delivery_time && d.tableid === -1
              ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
              : '',
        }));
        setCsvData(temCSVData);
        setOrderData(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const toggle = (id: any) => {
    setModal(!modal);
    setOrderId(id);
  };
  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const onChangeStatus = async (e: React.ChangeEvent<HTMLSelectElement>, order_number: any) => {
    const new_status = e.target.value;
    const formData = new FormData();
    formData.append('order_status', new_status);

    await API.restaurantApi
      .updateOrderStatus(String(order_number), formData)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const renderStatus = (currentStatus: string, order_number: any) => {
    if (currentStatus === 'Pending') {
      return (
        <select name="pets" id="pet-select" onChange={(e) => onChangeStatus(e, order_number)}>
          <option value="Pending" defaultChecked>
            {currentStatus}
          </option>
          <option value="Accepted">Accepted</option>
        </select>
      );
    } else {
      return currentStatus;
    }
  };

  // Autorefresh Orders List each 7 seconds
  useEffect(() => {
    const refres_interval = setInterval(() => {
      const rest_id = localStorage.getItem('rest_id') as string;
      getAllOrdersList(rest_id);
    }, 7000);
    return () => {
      clearInterval(refres_interval);
    };
  }, [getFilterdata]);

  const handleFilterStatus = (val: { value: string; label: string } | null) => {
    console.log('Select val', val);
    setFilterStatus(val);
  };

  // Styles For React Select
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      height: '27px',
      minHeight: '27px',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
      padding: '0 6px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
    }),
    option: (base: any) => ({
      ...base,
      height: '27px',
      textAlign: 'start',
      paddingTop: '5px',
    }),
  };

  const handleAutoAcceptOrders = async (val: boolean) => {
    // @ts-ignore
    const res = await API.restaurantApi.changeAutoAcceptOrders(restaurant.id, val);
  };

  const toggleAutoAcceptOrders = () => {
    console.log('toggle auto accept');
    handleAutoAcceptOrders(!isAutoAcceptOrders);
    setAutoAcceptOrders(!isAutoAcceptOrders);
  };

  const handlePrintNow = (order_id: any) => {
    console.log('Print Now', order_id);
    API.restaurantApi
      .printOrderNow(order_id)
      .then(() => {
        toast.success('Order was sent to printer!', { containerId: 'B' });
        const rest_id = localStorage.getItem('rest_id') as string;
        getAllOrdersList(rest_id);
      })
      .catch(() => {
        toast.error('Order was not sent to printer!', { containerId: 'B' });
      });
  };

  const renderPrintButton = (order_id: any, print_now: any) => {
    return (
      <button
        onClick={(e: any) => handlePrintNow(order_id)}
        disabled={print_now}
        className="btn btn-danger btn-sm"
      >
        Print
      </button>
    );
  };

  const showAsapDeliveryFirst = (orders: any[]) => {
    return orders.sort((a: any, b: any) => {
      if (a.asap_delivery && !b.asap_delivery) {
        return -1;
      } else if (!a.asap_delivery && b.asap_delivery) {
        return 1;
      } else {
        if (a.order_number > b.order_number) {
          return -1;
        } else {
          return 1;
        }
      }
    });
  };

  const renderDeliveryTime = (order: any) => {
    if (order.asap_delivery) {
      return 'ASAP';
    }
    if (order.delivery_time && order.tableid === -1) {
      return String(moment(order.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm'));
    }
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <div className="table-responsive">
              <span className="h4 mb-3"> Overzicht bestellingen</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div style={{ display: 'flex' }}>
                <form
                  style={{ display: 'flex' }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    getFilterdata();
                  }}
                >
                  <div>
                    <label
                      htmlFor="sel1"
                      className="control-label font-weight-bold m-0 mb-2 mt-3"
                      style={{ padding: '2px 10px 0px' }}
                    >
                      Start Date
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => SetStartDate(date)}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sel1"
                      className="control-label font-weight-bold m-0 mb-2 mt-3"
                      style={{ padding: '2px 10px 0px' }}
                    >
                      End Date
                    </label>
                    <DatePicker selected={endDate} onChange={(date) => SetendDate(date)} required />
                  </div>

                  <div style={{ minWidth: '10rem', marginTop: '0.8rem', marginLeft: '1.5rem' }}>
                    <Select
                      styles={selectStyles}
                      onChange={handleFilterStatus}
                      options={orderStatusSelectOpt}
                      defaultValue={filterStatus}
                    />
                  </div>

                  <div style={{ padding: '0px 20px 8px' }}>
                    <button
                      type="submit"
                      className="btn btn-success  shadow-none"
                      style={{
                        padding: '3px 33px 3px',
                        marginTop: '12px',
                        borderRadius: '7px',
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </form>

                <div style={{ marginTop: '11px' }} className="csv-data">
                  <CSVLink
                    data={data}
                    headers={headers}
                    target="_blank"
                    className="btn btn-primary btn-success btn-sm"
                    filename={`orderdata_${new Date()}.csv`}
                  >
                    Download CSV
                  </CSVLink>
                  {/* </button> */}
                </div>
              </div>
              <label className="d-flex align-items-center p-2" htmlFor="auto_accept">
                <span className="mr-2">Auto accept orders</span>
                <Switch
                  id="auto_accept"
                  onChange={(e) => toggleAutoAcceptOrders()}
                  onColor="#dc3545"
                  name="auto_accept_orders"
                  checked={isAutoAcceptOrders || false}
                />
              </label>
              {is_loading && <Loader />}
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="small"></th>
                    <th className="small">Order ID</th>
                    <th className="small">Status bestelling</th>
                    <th className="small">Betalingstatus</th>
                    <th className="small">Tafelnummer</th>
                    <th className="small">Opmerking klant</th>
                    <th className="small">Naam klant</th>
                    <th className="small">E-mail</th>
                    <th className="small">GSM</th>
                    <th className="small">Adres</th>
                    <th className="small">Bedrag</th>
                    <th className="small">Voorwanneer </th>
                    <th className="small">Bestelling</th>
                    <th className="small">ASAP delivery</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderData &&
                    showAsapDeliveryFirst(OrderData).map((order: any, index) => {
                      if (order.order_status !== filterStatus?.value) return null;
                      return (
                        <React.Fragment key={index}>
                          <tr className={`${order.asap_delivery && 'table-success'}`}>
                            <td className="small">
                              {renderPrintButton(order.order_number, order.print_now)}
                            </td>
                            <td className="small">{order.order_number}</td>
                            <td className="small">
                              {renderStatus(order.order_status, order.order_number)}
                            </td>
                            <td className="small">{String(order.payment_status)}</td>
                            <td className="small">{order.tableid === -1 ? '' : order.tableid}</td>
                            <td className="small">{order.user_note ? order.user_note : ''}</td>
                            <td className="small">
                              {order.customer_name ? order.customer_name : ''}
                            </td>
                            <td className="small">{order.email ? order.email : ''}</td>
                            <td className="small">{order.phoneNumber ? order.phoneNumber : ''}</td>
                            <td className="small">{order.address ? order.address : ''}</td>
                            <td className="small">{order.total_amount}</td>
                            <td className="small">{renderDeliveryTime(order)}</td>
                            <td className="small">
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={() => toggle(order.order_number)}
                              />
                            </td>
                            <td className="small">{String(order.asap_delivery)}</td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  <tr style={{ height: '2rem' }}></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OrderModel modal={modal} setModal={setModal} toggle={closeModal} orderId={orderId} />
    </>
  );
}

export default Orders;
